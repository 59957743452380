<div
  *ngIf="(matchMakingState | async) === MATCHMAKING_STATE.QueueWaiting"
  class="flex flex-col items-center space-y-1 p-6"
>
  <mat-spinner diameter="30" class="relative"></mat-spinner>
  <p class="p-3">{{ "searchingForPlayers.message" | translate }}</p>

  <button
    (click)="leaveQueue()"
    mat-raised-button
    class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
    type="button"
  >
    {{ "leaveQueue.button" | translate }}
  </button>
</div>

<div [hidden]="(matchMakingState | async) !== MATCHMAKING_STATE.QueueJoined" class="p-4 text-center">
  <mat-icon class="text-green-600">done</mat-icon>
  <p>{{ "playerJoined.message" | translate }}</p>
</div>
