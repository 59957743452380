<div
  *ngIf="(matchMakingState$ | async) === MATCHMAKING_STATE.None"
  class="flex flex-col items-center space-y-4 text-center"
>
  <mat-spinner diameter="30"></mat-spinner>
  {{ "privateGame.joining.message" | translate }}
</div>

<div
  *ngIf="(matchMakingState$ | async) === MATCHMAKING_STATE.PrivateLobbyJoined"
  class="flex flex-col items-center space-y-4 text-center"
>
  <mat-icon class="text-green-600">done</mat-icon>
  {{ "privateGame.joined.message" | translate }}
</div>

<div
  *ngIf="(matchMakingState$ | async) === MATCHMAKING_STATE.PrivateLobbyJoinFailed"
  class="flex flex-col items-center space-y-4 text-center"
>
  <mat-icon class="text-red-700">close</mat-icon>
  {{ "privateGame.notFound.message" | translate }}
  <button
    mat-raised-button
    class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
    routerLink="../"
    (click)="leaveFailedPrivateLobbyJoin()"
  >
    <em class="material-icons">backspace</em>
    <span class="pl-2">{{ "menu.button" | translate }}</span>
  </button>
</div>

<div class="visible mt-4">
  <ng2-adsense adSlot="6790344836"></ng2-adsense>
</div>
