import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Connect4GameState, GameEndType, GameState, GameType } from "@boardgames.io/messaging";
import { FrontendGameState } from "../+state/game/game.reducer";
import { Store } from "@ngrx/store";
import { selectGameState, selectIsMyTurn } from "../+state/game/game.selectors";
import { leaveGame, makeGameMove } from "../+state/game/game.actions";

export const emptyConnect4GameState: Connect4GameState = {
  id: "as",
  gameType: GameType.Connect4,
  players: [],
  board: [
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
  ],
  isFinished: true,
  turnCount: 0,
  startingPlayer: "",
  currentPlayer: "",
  opponentLeft: false,
  gameEndType: GameEndType.Regular,
  secondsPerMove: 30,
};

@Injectable({
  providedIn: "root",
})
export class GameService {
  public readonly gameState$: Observable<GameState | undefined>;
  public readonly isMyTurn$: Observable<boolean>;

  constructor(private readonly store: Store<{ game: FrontendGameState }>) {
    this.isMyTurn$ = this.store.select(selectIsMyTurn);
    this.gameState$ = this.store.select(selectGameState);
  }

  makeTurn(columnId: number) {
    this.store.dispatch(makeGameMove({ columnId }));
  }

  leaveGame() {
    this.store.dispatch(leaveGame());
  }
}
