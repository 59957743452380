import { FrontendGameState } from "./game.reducer";
import { createSelector } from "@ngrx/store";
import { WinnerState } from "../../services/post-game-lobby.service";

export const selectFrontendGameState = (state: { game: FrontendGameState }) => state.game;

export const selectWebsocketConnected = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.wsConnected,
);

export const selectGameType = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.gameType,
);

export const selectMatchmakingState = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.matchmakingState,
);

export const selectGameTitleTranslationKey = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.menu.titleTranslationKey,
);

export const selectOnlinePlayersCount = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.onlinePlayersCount,
);

export const selectMenuVisible = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.menu.visible,
);

export const selectMenuMaximized = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.menu.maximized,
);

export const selectGameState = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.gameState,
);

export const selectIsMyTurn = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.gameState?.currentPlayer === frontendGameState.playerId,
);

export const selectPostGameLobbyWinner = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => {
    let newState: WinnerState;
    if (frontendGameState.postGameLobby?.lastWinningPlayer === frontendGameState.playerId) {
      newState = WinnerState.Win;
    } else if (frontendGameState.postGameLobby?.lastWinningPlayer === undefined) {
      newState = WinnerState.Tie;
    } else {
      newState = WinnerState.Lose;
    }
    return newState;
  },
);

export const selectPostGameLobbyState = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.postGameLobbyState,
);

export const selectPostGameLobby = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.postGameLobby,
);

export const selectPrivateLobbyId = createSelector(
  selectFrontendGameState,
  (frontendGameState: FrontendGameState) => frontendGameState.privateLobbyState.privateLobbyId,
);
