<div [hidden]="hidden$ | async">
  <div class="half-transparent-container"></div>

  <mat-card appearance="outlined" class="disconnected-panel">
    <div class="flex flex-col items-center justify-center space-y-4">
      <mat-spinner diameter="30"></mat-spinner>
      <span class="text-center">Connection lost to the server. Attempting to reconnect ...</span>
    </div>
  </mat-card>
</div>
