import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { SettingsState } from "../+state/settings/settings.reducer";
import { setSecondsPerMove, toggleDarkMode, toggleSound } from "../+state/settings/settings.actions";
import { selectDarkModeEnabled, selectSecondsPerMove, selectSoundEnabled } from "../+state/settings/settings.selector";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  readonly soundEnabled$: Observable<boolean>;
  readonly darkModeEnabled$: Observable<boolean>;
  readonly secondsPerMove$: Observable<number>;

  constructor(private readonly store: Store<{ settings: SettingsState }>) {
    this.soundEnabled$ = this.store.select(selectSoundEnabled);
    this.darkModeEnabled$ = this.store.select(selectDarkModeEnabled);
    this.secondsPerMove$ = this.store.select(selectSecondsPerMove);
  }

  toggleSound() {
    this.store.dispatch(toggleSound());
  }

  toggleDarkMode() {
    this.store.dispatch(toggleDarkMode());
  }

  setTimeoutPerMove(secondsPerMove: number) {
    this.store.dispatch(setSecondsPerMove({ secondsPerMove }));
  }
}
