export enum GameType {
  None = "None",
  Connect4 = "Connect4",
  TicTacToe = "TicTacToe",
  UltimateTicTacToe = "SuperTicTacToe",
  Go = "Go",
  Mill = "Mill",
  Checkers = "Checkers",
  Gomoku = "Gomoku",
  Backgammon = "Backgammon",
  Battleship = "Battleship",
  Ludo = "Ludo",
  Congkak = "Congkak",
  Chess = "Chess",
  Gebeta = "Gebeta",
  Kalah = "Kalah",
  Makruk = "Makruk",
}
