import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PostGameLobby } from "@boardgames.io/messaging";
import { FrontendGameState } from "../+state/game/game.reducer";
import { Store } from "@ngrx/store";
import {
  postGameLobbyAcceptRematch,
  postGameLobbyDeclineRematch,
  postGameLobbyLeave,
  postGameLobbyRequestRematch,
} from "../+state/game/game.actions";
import {
  selectPostGameLobby,
  selectPostGameLobbyState,
  selectPostGameLobbyWinner,
} from "../+state/game/game.selectors";

export enum PostGameLobbyFrontendState {
  WaitingForResponse,
  Accepted,
  OpponentAccepted,
  Declined,
  OpponentDeclined,
  RematchRequestDeclined,
  OpponentLeft,
  None,
}

export enum WinnerState {
  Win,
  Lose,
  Tie,
  None,
}

@Injectable({
  providedIn: "root",
})
export class PostGameLobbyService {
  winnerState$: Observable<WinnerState>;
  postGameLobbyState$: Observable<PostGameLobbyFrontendState>;
  postGameLobby$: Observable<PostGameLobby | undefined>;

  constructor(private readonly store: Store<{ game: FrontendGameState }>) {
    this.winnerState$ = this.store.select(selectPostGameLobbyWinner);
    this.postGameLobbyState$ = this.store.select(selectPostGameLobbyState);
    this.postGameLobby$ = this.store.select(selectPostGameLobby);
  }

  requestRematch() {
    this.store.dispatch(postGameLobbyRequestRematch());
  }

  acceptRematch() {
    this.store.dispatch(postGameLobbyAcceptRematch());
  }

  declineRematch() {
    this.store.dispatch(postGameLobbyDeclineRematch());
  }

  leavePostGameScreen() {
    this.store.dispatch(postGameLobbyLeave());
  }
}
