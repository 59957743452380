import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Meta, Title } from "@angular/platform-browser";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  constructor(
    public translateService: TranslateService,
    public localizeRouterService: LocalizeRouterService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
  ) {
    this.updateHeaderOnRouterChange();
    this.updateHtmlHeader(this.getGameTypeFromCurrentRoute());

    this.setLanguageOnHtmlTag(this.translateService.currentLang);
  }

  private updateHeaderOnRouterChange() {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.updateHtmlHeader(this.getGameTypeFromCurrentRoute());
      }
    });
  }

  private getGameTypeFromCurrentRoute(): string {
    return this.router.url.split("/")[2] || "home";
  }

  public changeTranslation(language: string) {
    this.translateService.use(language);
    this.localizeRouterService.changeLanguage(language);

    this.updateHtmlHeader(this.getGameTypeFromCurrentRoute());
    this.setLanguageOnHtmlTag(language);
  }

  public updateHtmlHeader(gameType: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.translateService.get([`${gameType}.header.title`, `${gameType}.header.description`]).subscribe((data: any) => {
      this.titleService.setTitle(data[`${gameType}.header.title`]);
      this.metaService.updateTag({
        name: "description",
        content: data[`${gameType}.header.description`],
      });
    });
  }

  private setLanguageOnHtmlTag(language: string) {
    document.documentElement.lang = language;
  }
}
