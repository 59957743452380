import { Injectable } from "@angular/core";
import { FrontendGameState } from "../+state/game/game.reducer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectGameTitleTranslationKey, selectMenuMaximized, selectMenuVisible } from "../+state/game/game.selectors";
import { switchPostGameLobbyMenuMaximizationState } from "../+state/game/game.actions";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public readonly menuVisible$: Observable<boolean>;
  public readonly menuMaximized$: Observable<boolean>;
  gameTitleTranslationKey$: Observable<string>;

  constructor(readonly store: Store<{ game: FrontendGameState }>) {
    this.menuVisible$ = this.store.select(selectMenuVisible);
    this.menuMaximized$ = this.store.select(selectMenuMaximized);
    this.gameTitleTranslationKey$ = this.store.select(selectGameTitleTranslationKey);
  }

  switchMenuStates() {
    this.store.dispatch(switchPostGameLobbyMenuMaximizationState());
  }
}
