<button mat-button id="languageSelectionButton" [matMenuTriggerFor]="menu" class="float-right">
  <img class="inline" alt="globe language selection" src="assets/globe.svg" height="18" width="18" />
  <p class="ml-2 inline dark:text-slate-300">
    {{ "language" | translate }}
  </p>
</button>
<mat-menu #menu="matMenu" class="dark:bg-slate-800">
  <button
    *ngFor="let language of languages"
    class="dark:text-slate-300"
    (click)="useLanguage(language.code)"
    mat-menu-item
  >
    <p class="ml-2 inline dark:text-slate-300">
      {{ language.name }}
    </p>
  </button>
</mat-menu>
