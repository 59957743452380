import { createReducer, on } from "@ngrx/store";
import {
  changeGameType,
  exitedPrivateLobby,
  gameStateUpdate,
  joinedPrivateLobby,
  joinPrivateLobbyRequestFailed,
  joinQueueRequest,
  leaveFailedPrivateLobbyJoin,
  postGameLobbyUpdateReceived,
  privateLobbyCreated,
  privateLobbyOpponentJoined,
  queueLeft,
  queuePlayerFound,
  queueWaiting,
  switchPostGameLobbyMenuMaximizationState,
  updateOnlinePlayersCount,
  updatePlayerId,
  wsConnectionUpdate,
} from "./game.actions";
import { MatchmakingState } from "../../services/match-making.service";
import {
  GameState,
  GameType,
  getPostGameLobbyPlayerByPlayerId,
  getPostGameLobbyPlayerOpponentByPlayerId,
  PostGameLobby,
  PostGameLobbyState,
} from "@boardgames.io/messaging";
import { PostGameLobbyFrontendState } from "../../services/post-game-lobby.service";

export const initialGameState: FrontendGameState = {
  onlinePlayersCount: 0,
  playerId: undefined,
  wsConnected: false,
  gameType: GameType.None,
  menu: {
    titleTranslationKey: "",
    visible: true,
    maximized: true,
  },
  matchmakingState: MatchmakingState.None,
  privateLobbyState: {
    privateLobbyId: undefined,
    qrCodeVisible: false,
  },
  gameState: undefined,
  postGameLobby: undefined,
  postGameLobbyState: PostGameLobbyFrontendState.None,
};

export interface FrontendGameState {
  playerId: string | undefined;
  onlinePlayersCount: number;
  wsConnected: boolean;
  gameType: GameType;
  menu: MenuState;
  matchmakingState: MatchmakingState;
  privateLobbyState: PrivateLobyState;
  gameState: GameState | undefined;
  postGameLobby: PostGameLobby | undefined;
  postGameLobbyState: PostGameLobbyFrontendState;
}

interface MenuState {
  titleTranslationKey: string;
  visible: boolean;
  maximized: boolean;
}

interface PrivateLobyState {
  privateLobbyId: string | undefined;
  qrCodeVisible: boolean;
}

export const gameReducer = createReducer(
  initialGameState,
  on(changeGameType, (state: FrontendGameState, { gameType }) => ({
    ...state,
    gameType: gameType,
    gameState: undefined,
    matchmakingState: MatchmakingState.None,
    privateLobbyId: undefined,
    menu: {
      titleTranslationKey: getMenuTitleTranslationKey(gameType),
      visible: true,
      maximized: true,
    },
  })),
  on(joinQueueRequest, (state) => {
    if (state.gameType !== GameType.None && state.gameState === undefined) {
      return { ...state, matchmakingState: MatchmakingState.QueueJoined };
    } else {
      return { ...state };
    }
  }),
  on(queueWaiting, (state) => {
    return { ...state, matchmakingState: MatchmakingState.QueueWaiting };
  }),
  on(queuePlayerFound, (state) => {
    return { ...state, matchmakingState: MatchmakingState.QueueJoined, menu: { ...state.menu, visible: false } };
  }),
  on(gameStateUpdate, (state, { gameState }) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.None,
      gameState,
      menu: { ...state.menu, visible: gameState.isFinished, maximized: gameState.isFinished },
    };
  }),
  on(queueLeft, (state) => {
    return { ...state, matchmakingState: MatchmakingState.None };
  }),
  on(privateLobbyCreated, (state, { lobbyId }) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.PrivateLobbyWaiting,
      privateLobbyState: { ...state.privateLobbyState, privateLobbyId: lobbyId },
    };
  }),
  on(exitedPrivateLobby, (state) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.None,
      privateLobbyState: {
        privateLobbyId: undefined,
        qrCodeVisible: false,
      },
      privateLobbyId: undefined,
    };
  }),
  on(wsConnectionUpdate, (state: FrontendGameState, { connected }) => {
    return { ...state, wsConnected: connected };
  }),
  on(updatePlayerId, (state: FrontendGameState, { playerId }) => {
    return { ...state, playerId };
  }),
  on(updateOnlinePlayersCount, (state: FrontendGameState, { onlinePlayersCount }) => {
    return { ...state, onlinePlayersCount };
  }),
  on(postGameLobbyUpdateReceived, (state: FrontendGameState, { postGameLobby }) => {
    return {
      ...state,
      postGameLobby: postGameLobby,
      postGameLobbyState: updatePostGameLobbyState(state, postGameLobby),
    };
  }),
  on(switchPostGameLobbyMenuMaximizationState, (state: FrontendGameState) => {
    return {
      ...state,
      menu: { ...state.menu, maximized: !state.menu.maximized },
    };
  }),
  on(joinedPrivateLobby, (state: FrontendGameState, { success }) => {
    return {
      ...state,
      matchmakingState: success ? MatchmakingState.PrivateLobbyJoined : MatchmakingState.PrivateLobbyJoinFailed,
      menu: { ...state.menu, maximized: true, visible: !success },
    };
  }),
  on(privateLobbyOpponentJoined, (state: FrontendGameState) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.PrivateLobbyJoined,
      menu: { ...state.menu, maximized: true, visible: false },
    };
  }),
  on(joinPrivateLobbyRequestFailed, (state: FrontendGameState) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.PrivateLobbyJoinFailed,
      menu: { ...state.menu, maximized: true, visible: true },
    };
  }),
  on(leaveFailedPrivateLobbyJoin, (state: FrontendGameState) => {
    return {
      ...state,
      matchmakingState: MatchmakingState.None,
    };
  }),
);

function updatePostGameLobbyState(state: FrontendGameState, postGameLobby: PostGameLobby) {
  const myState = getPostGameLobbyPlayerByPlayerId(postGameLobby, state.playerId as string)?.state;
  const opponentState = getPostGameLobbyPlayerOpponentByPlayerId(postGameLobby, state.playerId as string)?.state;
  let postGameLobbyState = PostGameLobbyFrontendState.None;
  if (myState === PostGameLobbyState.ReadyForRematch && opponentState === PostGameLobbyState.Joined) {
    postGameLobbyState = PostGameLobbyFrontendState.WaitingForResponse;
  } else if (myState === PostGameLobbyState.ReadyForRematch && opponentState === PostGameLobbyState.ReadyForRematch) {
    postGameLobbyState = PostGameLobbyFrontendState.Accepted;
  } else if (myState === PostGameLobbyState.ReadyForRematch && opponentState === PostGameLobbyState.NoRematch) {
    postGameLobbyState = PostGameLobbyFrontendState.OpponentDeclined;
  } else if (myState === PostGameLobbyState.ReadyForRematch && opponentState === PostGameLobbyState.Left) {
    postGameLobbyState = PostGameLobbyFrontendState.OpponentLeft;
  } else if (myState === PostGameLobbyState.NoRematch && opponentState === PostGameLobbyState.ReadyForRematch) {
    postGameLobbyState = PostGameLobbyFrontendState.Declined;
  } else if (myState === PostGameLobbyState.Joined && opponentState === PostGameLobbyState.ReadyForRematch) {
    postGameLobbyState = PostGameLobbyFrontendState.RematchRequestDeclined;
  } else if (myState === PostGameLobbyState.ReadyForRematch && postGameLobby.opponentLeft) {
    postGameLobbyState = PostGameLobbyFrontendState.OpponentLeft;
  }
  return postGameLobbyState;
}

function getMenuTitleTranslationKey(gameType: GameType): string {
  switch (gameType) {
    case GameType.Connect4:
      return "connect4.header";
    case GameType.TicTacToe:
      return "tictactoe.header";
  }

  return "";
}
