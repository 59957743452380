import { AfterViewInit, Component, HostListener } from "@angular/core";
import { GameViewContentService } from "../services/game-view-content.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  constructor(private readonly gameViewContent: GameViewContentService) {
    this.disableScrollingMobile();
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.gameViewContent.viewWidth.next(window.innerWidth);
    this.gameViewContent.viewHeight.next(window.innerHeight);
  }

  private disableScrollingMobile() {
    window.addEventListener("scroll", (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
    });
  }
}
