import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatchMakingService, MatchmakingState } from "../../../services/match-making.service";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { leaveFailedPrivateLobbyJoin } from "../../../+state/game/game.actions";

@Component({
  selector: "app-private-lobby-join",
  templateUrl: "./join-game.component.html",
  styleUrls: ["./join-game.component.scss"],
})
export class JoinGameComponent implements OnInit, OnDestroy {
  matchMakingState$: Observable<MatchmakingState>;

  MATCHMAKING_STATE = MatchmakingState;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    readonly matchmakingService: MatchMakingService,
  ) {}

  ngOnInit() {
    this.matchMakingState$ = this.matchmakingService.matchmakingState$;
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        const lobbyId = params["id"];
        this.matchmakingService.joinPrivateGame(lobbyId);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  leaveFailedPrivateLobbyJoin() {
    this.matchmakingService.leaveFailedPrivateLobbyJoin();
  }
}
