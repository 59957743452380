import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { MatchmakingState } from "../../../services/match-making.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { maxSecondsPerMove, minSecondsPerMove, PrivateLobbySettingsMessage } from "@boardgames.io/messaging";
import { SettingsService } from "../../../services/settings.service";
import { debounceTime, of, Subject, Subscription } from "rxjs";

@Component({
  selector: "app-private-lobby-menu",
  templateUrl: "./private-lobby-menu.component.html",
  styleUrls: ["./private-lobby-menu.component.scss"],
})
export class PrivateLobbyMenuComponent implements OnInit, OnChanges, OnDestroy {
  MatchmakingState = MatchmakingState;

  @Input()
  lobbyId: string;

  @Input()
  matchMakingState: MatchmakingState;

  @Output()
  leave = new EventEmitter();

  @Output()
  updatePrivateLobby = new EventEmitter<PrivateLobbySettingsMessage>();

  invitationLink: string;
  qrCodeVisible$ = of(true);
  private timeoutPerMoveSubject = new Subject<number>();
  private subscriptions: Subscription[] = [];

  get timeoutPerMove() {
    return 30;
  }

  constructor(
    private clipboard: Clipboard,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.timeoutPerMoveSubject
        .pipe(debounceTime(1000))
        .subscribe((timeoutPerMove) => this.updateTimeoutPerMove(timeoutPerMove)),
    );
    this.updateInvitationLink();
  }

  ngOnChanges(): void {
    this.updateInvitationLink();
    console.log("PrivateLobbyMenuComponent ngOnChanges");
    console.log(this.invitationLink);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  updateInvitationLink(): void {
    const port = window.location.port === "" ? "" : `:${window.location.port}`;
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const routerUrl = this.router.url;

    this.invitationLink = `${protocol}//${hostname}${port}${routerUrl}/join?id=${this.lobbyId}`;
  }

  exitPrivateLobbyClicked() {
    this.leave.emit();
  }

  copyLinkToClipboard() {
    this.clipboard.copy(this.invitationLink);
  }

  showCopiedSnackbar() {
    const message = this.translateService.instant("copied");
    const closeMessage = this.translateService.instant("close");
    this.snackBar.open(message, closeMessage, {
      duration: 2000,
    });
  }

  shareLink() {
    navigator
      .share({
        title: "Boardgames.io private match link",
        url: this.invitationLink,
      })
      .catch();
  }

  onTimeoutPerMoveChange(timeoutPerMove: number) {
    this.timeoutPerMoveSubject.next(timeoutPerMove);
  }

  private updateTimeoutPerMove(secondsPerMove: number) {
    secondsPerMove = Math.max(minSecondsPerMove, secondsPerMove);
    secondsPerMove = Math.min(maxSecondsPerMove, secondsPerMove);
    this.settingsService.setTimeoutPerMove(secondsPerMove);

    this.updatePrivateLobby.emit({ lobbyId: this.lobbyId, secondsPerMove });
  }
}
