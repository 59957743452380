import { createReducer, on } from "@ngrx/store";
import { defaultSecondsPerMove } from "@boardgames.io/messaging";
import { setSecondsPerMove, toggleDarkMode, toggleSound } from "./settings.actions";

export interface SettingsState {
  isSoundEnabled: boolean;
  isDarkModeEnabled: boolean;
  secondsPerMove: number;
}

export const initialSettings: SettingsState = {
  isSoundEnabled: true,
  isDarkModeEnabled: false,
  secondsPerMove: defaultSecondsPerMove,
};

export const settingsReducer = createReducer(
  initialSettings,
  on(toggleSound, (settings) => ({
    ...settings,
    isSoundEnabled: !settings.isSoundEnabled,
  })),
  on(toggleDarkMode, (settings) => ({
    ...settings,
    isDarkModeEnabled: !settings.isDarkModeEnabled,
  })),
  on(setSecondsPerMove, (settings, { secondsPerMove }) => ({
    ...settings,
    secondsPerMove: secondsPerMove,
  })),
);
