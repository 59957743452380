import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatchmakingState } from "../../../services/match-making.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-queue-menu",
  templateUrl: "./queue-menu.component.html",
  styleUrls: ["./queue-menu.component.scss"],
})
export class QueueMenuComponent {
  MATCHMAKING_STATE = MatchmakingState;

  @Input()
  matchMakingState: Observable<MatchmakingState>;

  @Output()
  leave = new EventEmitter();

  leaveQueue() {
    this.leave.emit();
  }
}
