<mat-card-title [hidden]="menuService.menuMaximized$ | async">
  {{ "gameEnd.title" | translate }}
  <button (click)="switchMenuMaximizationStates()" class="float-right dark:text-slate-300" mat-button>
    <span *ngIf="menuService.menuMaximized$ | async">{{ "minimize.label" | translate }}</span>
    <span *ngIf="(menuService.menuMaximized$ | async) === false">{{ "maximize.label" | translate }}</span>
  </button>
</mat-card-title>
<mat-card-content *ngIf="menuService.menuMaximized$ | async">
  <div class="flex w-full flex-col items-center space-y-4">
    <p class="flex flex-col items-center text-lg">
      <span *ngIf="winnerState === WinnerState.Win" class="text-green-600">
        {{ "gameWon.message" | translate }}
      </span>
      <span *ngIf="winnerState === WinnerState.Lose" class="text-red-800">
        {{ "gameLost.message" | translate }}
      </span>
      <span *ngIf="winnerState === WinnerState.Tie">
        {{ "gameTie.message" | translate }}
      </span>
      <span *ngIf="postGameLobby?.opponentLeft">{{ "opponentLeft.message" | translate }}</span>
      <span *ngIf="postGameLobby?.gameEndType === GameEndType.Inactive && winnerState === WinnerState.Win">{{
        "opponentInactive.message" | translate
      }}</span>
      <span *ngIf="postGameLobby?.gameEndType === GameEndType.Inactive && winnerState === WinnerState.Lose">{{
        "inactive.message" | translate
      }}</span>
    </p>

    <button
      mat-raised-button
      class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
      *ngIf="rematchRequestState === PostGameLobbyState.None"
      (click)="requestRematch()"
      [disabled]="false"
    >
      <mat-icon>cached</mat-icon>
      {{ "rematch.button" | translate }}
    </button>

    <div
      *ngIf="rematchRequestState === PostGameLobbyState.RematchRequestDeclined"
      class="flex flex-col space-x-4 space-y-4"
    >
      <p class="text-center">
        {{ "rematch.request.received.message" | translate }}
      </p>
      <div class="flex space-x-2">
        <button
          mat-raised-button
          (click)="acceptRematch()"
          class="bg-btn-primary dark:bg-btn-primary-d mr-1 text-white dark:text-slate-300"
        >
          <mat-icon>done</mat-icon>
          {{ "rematch.accept.button" | translate }}
        </button>
        <button
          mat-raised-button
          class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
          (click)="declineRematch()"
        >
          <mat-icon>close</mat-icon>
          {{ "rematch.decline.button" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="rematchRequestState === PostGameLobbyState.WaitingForResponse"
      class="flex flex-col items-center space-y-2"
    >
      <mat-spinner diameter="30"></mat-spinner>
      <p>{{ "rematch.waiting.message" | translate }}</p>
    </div>

    <div
      *ngIf="rematchRequestState === PostGameLobbyState.OpponentDeclined"
      class="flex flex-col items-center space-y-2"
    >
      <mat-icon class="text-red-600">close</mat-icon>
      <span>{{ "rematch.opponent.declined.message" | translate }}</span>
    </div>

    <div
      *ngIf="rematchRequestState === PostGameLobbyState.OpponentAccepted"
      class="flex flex-col items-center space-y-2 whitespace-pre-wrap text-center"
    >
      <mat-icon class="text-green-600">done</mat-icon>
      <span>{{ "rematch.opponent.accepted.message" | translate }}</span>
    </div>

    <div *ngIf="rematchRequestState === PostGameLobbyState.Accepted" class="flex flex-col items-center space-y-2">
      <mat-icon class="text-green-600">done</mat-icon>
      <span>{{ "rematch.gameStarting.message" | translate }}</span>
    </div>

    <div *ngIf="rematchRequestState === PostGameLobbyState.Declined">
      <span>{{ "rematch.declined.message" | translate }}</span>
    </div>

    <div *ngIf="rematchRequestState === PostGameLobbyState.OpponentLeft" class="flex flex-col items-center space-y-2">
      <mat-icon class="text-red-600">close</mat-icon>
      <span>{{ "rematch.opponentLeft.message" | translate }}</span>
    </div>

    <button
      mat-raised-button
      class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
      routerLink="../"
      *ngIf="
        !(
          rematchRequestState === PostGameLobbyState.Accepted ||
          rematchRequestState === PostGameLobbyState.OpponentAccepted
        )
      "
    >
      <mat-icon>backspace</mat-icon>
      {{ "menu.button" | translate }}
    </button>
  </div>
  <div class="visible mt-4">
    <ng2-adsense adSlot="6790344836"></ng2-adsense>
  </div>
</mat-card-content>
