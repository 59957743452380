import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { FrontendGameState } from "../+state/game/game.reducer";
import { selectOnlinePlayersCount } from "../+state/game/game.selectors";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  readonly onlinePlayersCount$: Observable<number>;

  constructor(readonly store: Store<{ game: FrontendGameState }>) {
    this.onlinePlayersCount$ = this.store.select(selectOnlinePlayersCount);
  }
}
