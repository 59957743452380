import { createAction, props } from "@ngrx/store";
import { GameState, GameType, PostGameLobby, PrivateLobbySettingsMessage } from "@boardgames.io/messaging";

export const wsConnectionUpdate = createAction("[Game] Websocket connected", props<{ connected: boolean }>());
export const updatePlayerId = createAction("[Game] Update PlayerId", props<{ playerId: string }>());
export const updateOnlinePlayersCount = createAction(
  "[Game] Update OnlinePlayersCount",
  props<{ onlinePlayersCount: number }>(),
);

export const changeGameType = createAction("[Game] Update GameType", props<{ gameType: GameType }>());

export const joinQueueRequest = createAction("[Game] Join public queue request");
export const queueWaiting = createAction("[Game] Waiting in public queue");
export const queuePlayerFound = createAction("[Game] Player found");
export const leaveQueue = createAction("[Game] Leave public queue");
export const queueLeft = createAction("[Game] Left public queue");

export const createPrivateLobby = createAction("[Game] Create private lobby");

export const privateLobbyCreated = createAction("[Game] Private lobby created", props<{ lobbyId: string }>());
export const privateLobbyOpponentJoined = createAction("[Game] Private lobby opponent joined");
export const exitPrivateLobby = createAction("[Game] Exit private lobby");
export const exitedPrivateLobby = createAction("[Game] Exited private lobby");

export const joinPrivateLobby = createAction("[Game] Join private lobby", props<{ lobbyId: string }>());
export const joinedPrivateLobby = createAction("[Game] Joined private lobby", props<{ success: boolean }>());
export const leaveFailedPrivateLobbyJoin = createAction("[Game] Leave failed private lobby join");

export const makeGameMove = createAction("[Game] Make move", props<{ columnId: unknown }>());

export const leaveGame = createAction("[Game] Leave game");
export const gameStateUpdate = createAction("[Game] Game state update", props<{ gameState: GameState }>());

export const postGameLobbyRequestRematch = createAction("[Game] Post game lobby request rematch");
export const postGameLobbyAcceptRematch = createAction("[Game] Post game lobby accept rematch");
export const postGameLobbyDeclineRematch = createAction("[Game] Post game lobby decline rematch");
export const switchPostGameLobbyMenuMaximizationState = createAction(
  "[Game] Post game lobby switch maximization state",
);

export const privateLobbySettingsSendUpdate = createAction(
  "[Game] Post game lobby settings send update",
  props<{ privateLobbySettings: PrivateLobbySettingsMessage }>(),
);

export const joinPrivateLobbyRequest = createAction("[Game] Join private lobby request", props<{ lobbyId: string }>());
export const joinPrivateLobbyRequestFailed = createAction("[Game] Join private lobby request failed");

export const postGameLobbyLeave = createAction("[Game] Post game lobby leave");

export const postGameLobbyUpdateReceived = createAction(
  "[Game] Post game lobby update received",
  props<{ postGameLobby: PostGameLobby }>(),
);
