import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap, withLatestFrom } from "rxjs/operators";
import { WebsocketService } from "../../services/websocket.service";
import { Store } from "@ngrx/store";
import { toggleDarkMode } from "./settings.actions";
import { selectDarkModeEnabled } from "./settings.selector";
import { SettingsState } from "./settings.reducer";

@Injectable()
export class SettingsEffects {
  darkModeChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleDarkMode),
        withLatestFrom(this.store.select(selectDarkModeEnabled)),
        tap(([, darkModeEnabled]) => {
          const htmlTag = document.getElementsByTagName("html").item(0);
          const classList = htmlTag?.classList;

          if (darkModeEnabled) {
            if (!classList?.contains("dark")) {
              classList?.add("dark");
            }
          } else {
            if (classList?.contains("dark")) {
              classList?.remove("dark");
            }
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<{ settings: SettingsState }>,
    private websocketService: WebsocketService,
  ) {}
}
