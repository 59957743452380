import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { GameService } from "../../../../services/game.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuService } from "../../../../services/menu.service";
import { Subscription } from "rxjs";
import { GameEndType, GameType, TicTacToeGameState } from "@boardgames.io/messaging";
import { SoundService, SoundType } from "../../../../services/sound.service";
import { GameViewContentService } from "../../../../services/game-view-content.service";

const emptyTicTacToeState = {
  id: "as",
  gameType: GameType.Connect4,
  players: [],
  board: ["", "", "", "", "", "", "", "", ""],
  isFinished: true,
  turnCount: 0,
  startingPlayer: "",
  currentPlayer: "",
  opponentLeft: false,
  gameEndType: GameEndType.None,
  secondsPerMove: 30,
};

@Component({
  selector: "tic-tac-toe-game-field",
  templateUrl: "./tic-tac-toe-game-field.component.html",
  styleUrls: ["tic-tac-toe-game-field.component.scss"],
})
export class TicTacToeGameFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  gameState: TicTacToeGameState = emptyTicTacToeState;

  subscriptions: Subscription[] = [];
  @ViewChild("gameField") gameField: ElementRef;

  constructor(
    public gameService: GameService,
    readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly menuService: MenuService,
    private readonly soundService: SoundService,
    private readonly gameViewContent: GameViewContentService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.gameService.gameState$.subscribe((gameState) => {
        if (gameState === undefined) {
          this.gameState = emptyTicTacToeState;
        } else {
          const newGameState = gameState as TicTacToeGameState;

          if (newGameState.turnCount > this.gameState.turnCount && newGameState.turnCount != 0) {
            this.soundService.playSound(SoundType.MakeMove);
          }

          this.gameState = newGameState;

          if (this.gameState.isFinished && this.gameState != emptyTicTacToeState) {
            this.router
              .navigate(["gameEnd"], {
                relativeTo: this.activatedRoute,
              })
              .catch();
          }
        }
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.gameViewContent.viewHeight.subscribe((height) => {
        let maxheight = this.gameViewContent.maxGameFieldHeight(height) - 100;
        if (maxheight > 700) {
          maxheight = 700;
        }
        this.renderer.setStyle(this.gameField.nativeElement, "max-width", `${maxheight}px`);
        this.renderer.setStyle(this.gameField.nativeElement, "max-height", `${maxheight}px`);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  makeMove(fieldId: number) {
    if (this.gameState.gameType != GameType.None && !this.gameState.isFinished) {
      this.gameService.makeTurn(fieldId);
      console.log("makeMove");
    }
  }

  displayX(stoneEntry?: string) {
    return stoneEntry === this.gameState?.players[0];
  }

  displayO(stoneEntry?: string) {
    return stoneEntry === this.gameState?.players[1];
  }

  displayEmpty(stoneEntry?: string) {
    return !this.displayO(stoneEntry) && !this.displayX(stoneEntry);
  }
}
