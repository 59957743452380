import { Component } from "@angular/core";
import { FeatureFlagService } from "../../services/feature-flag.service";

@Component({
  selector: "boardgames.io-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  constructor(public featureFlagService: FeatureFlagService) {}
}
