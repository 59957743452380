import { Component, OnInit } from "@angular/core";
import { delay, Observable, of, switchMap } from "rxjs";
import { MenuService } from "../../../services/menu.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { map } from "rxjs/operators";

export enum MenuSize {
  MAXIMIZED = "MAXIMIZED",
  MINIMIZED = "MINIMIZED",
}

@Component({
  selector: "menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  animations: [
    trigger("menuSize", [
      state(
        MenuSize.MAXIMIZED,
        style({
          top: "50%",
        }),
      ),
      state(
        MenuSize.MINIMIZED,
        style({
          top: "93%",
        }),
      ),
      transition(`${MenuSize.MAXIMIZED} => ${MenuSize.MINIMIZED}`, animate(250, style({ top: "93%" }))),
      transition(`${MenuSize.MINIMIZED} => ${MenuSize.MAXIMIZED}`, animate(250, style({ top: "50%" }))),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  menuVisible$: Observable<boolean>;
  menuMaximized$: Observable<MenuSize>;

  constructor(private readonly menuService: MenuService) {}

  ngOnInit(): void {
    this.menuMaximized$ = this.menuService.menuMaximized$.pipe(
      map((isMaximized) => (isMaximized ? MenuSize.MAXIMIZED : MenuSize.MINIMIZED)),
    );
    this.menuVisible$ = this.menuService.menuVisible$.pipe(
      switchMap((visible) => {
        return visible ? of(visible) : of(visible).pipe(delay(2000));
      }),
    );
  }
}
