import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { RegisterAnonymousUserResponseDto } from "@boardgames.io/shared/auth-service-messaging";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(httpClient: HttpClient) {
    console.log("UserService constructor called");
    const anonymousUser = localStorage.getItem("anonymousUser");
    if (!anonymousUser) {
      httpClient
        .post<RegisterAnonymousUserResponseDto>(`${this.getAuthUri()}/register/anonymous`, {})
        .subscribe((response) => {
          localStorage.setItem("anonymousUser", JSON.stringify(response));
        });
    }
  }

  private getAuthUri(): string {
    if (environment.production) {
      return `https://api.${window.location.hostname}`;
    } else {
      return environment.auth_uri;
    }
  }
}
