import { ChangeDetectionStrategy, Component } from "@angular/core";
import { StatisticsService } from "../../services/statistics.service";

@Component({
  selector: "boardgames.io-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(public readonly statisticsService: StatisticsService) {}
}
