import { GameType } from "./gameType";
import { IsDefined, IsNumber, IsString } from "class-validator";

export enum QueueMessage {
  Join = "queue:join",
  Leave = "queue:leave",
  PlayerFound = "queue:playerFound",
}

export enum PrivateLobbyMessage {
  Create = "privateLobby:create",
  CreateV2 = "privateLobby:createV2",
  Created = "privateLobby:created",
  Join = "privateLobby:join",
  OpponentJoined = "privateLobby:opponentJoined",
  Leave = "privateLobby:leave",
  UpdateSettings = "privateLobby:updateSettings",
}

export interface CreatePrivateLobbyMessage {
  gameType: GameType;
  secondsPerMove: number;
}

export class PrivateLobbySettingsMessage {
  @IsString()
  @IsDefined()
  lobbyId: string;

  @IsNumber()
  @IsDefined()
  secondsPerMove: number;
}
