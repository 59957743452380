import { Component } from "@angular/core";
import { MatchMakingService, MatchmakingState } from "../../../services/match-making.service";
import { MenuService } from "../../../services/menu.service";
import { Observable } from "rxjs";
import { PrivateLobbySettingsMessage } from "@boardgames.io/messaging";
import { SettingsService } from "../../../services/settings.service";
import { StatisticsService } from "../../../services/statistics.service";

export interface Language {
  displayName: string;
  abbreviation: string;
}

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent {
  MATCHMAKING_STATE = MatchmakingState;

  gameTitleTranslationKey$: Observable<string>;
  matchMakingState$: Observable<MatchmakingState>;
  darkModeEnabled$: Observable<boolean>;
  onlinePlayersCount$: Observable<number>;
  privateLobbyId$: Observable<string | undefined>;

  constructor(
    private readonly matchMakingService: MatchMakingService,
    private readonly settingsService: SettingsService,
    private readonly statisticsService: StatisticsService,
    private readonly menuService: MenuService,
  ) {
    this.matchMakingState$ = this.matchMakingService.matchmakingState$;
    this.gameTitleTranslationKey$ = this.menuService.gameTitleTranslationKey$;
    this.darkModeEnabled$ = this.settingsService.darkModeEnabled$;
    this.onlinePlayersCount$ = this.statisticsService.onlinePlayersCount$;
    this.privateLobbyId$ = this.matchMakingService.privateLobbyId$;
  }

  leavePrivateLobby() {
    this.matchMakingService.leavePrivateLobby();
  }

  joinQueue() {
    this.matchMakingService.joinQueue();
  }

  leaveQueue() {
    this.matchMakingService.leaveQueue();
  }

  createPrivateLobbyClicked() {
    this.matchMakingService.createPrivateLobby();
  }

  updatePrivateLobby(privateLobbySettings: PrivateLobbySettingsMessage) {
    this.matchMakingService.updatePrivateLobbySettings(privateLobbySettings);
  }

  toggleDarkMode() {
    this.settingsService.toggleDarkMode();
  }
}
