<div class="flex h-full w-full flex-col items-center justify-between">
  <div id="top-dummy"></div>
  <div>
    <div class="flex w-full flex-col items-center justify-center p-8">
      <span class="pb-2 text-4xl">Boardgames.io</span>
      <span *ngIf="this.statisticsService.onlinePlayersCount$ | async as number"
        >{{ this.statisticsService.onlinePlayersCount$ | async }} players online</span
      >
    </div>
    <div class="flex w-full flex-row flex-wrap justify-center space-x-4">
      <button
        mat-raised-button
        routerLink="connect4"
        class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
      >
        {{ "connect4.header" | translate }}
      </button>
      <button
        mat-raised-button
        class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
        routerLink="tictactoe"
      >
        {{ "tictactoe.header" | translate }}
      </button>
    </div>
  </div>

  <div class="pb-10 text-center">
    <app-download-links></app-download-links>
  </div>
</div>
