import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  isProd(): boolean {
    return window.location.host === "boardgames.io";
  }
}
