<div class="block flex w-full flex-row pb-5">
  <div class="flex flex-grow flex-col">
    <p class="text-lg font-medium">{{ (gameTitleTranslationKey$ | async) ?? "" | translate }}</p>
    <span *ngIf="onlinePlayersCount$ | async as number" class="text-sm font-light">
      {{ "onlinePlayers" | translate: { value: onlinePlayersCount$ | async } }}
    </span>
  </div>
  <div>
    <button (click)="toggleDarkMode()" class="mt-2">
      <mat-icon *ngIf="darkModeEnabled$ | async">wb_sunny</mat-icon>
      <mat-icon *ngIf="(darkModeEnabled$ | async) === false">mode_night</mat-icon>
    </button>
    <language-switch></language-switch>
  </div>
</div>

<div class="flex flex-nowrap justify-center space-x-4 pb-4 align-middle">
  <button
    mat-raised-button
    class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
    (click)="joinQueue()"
    [disabled]="
      (matchMakingState$ | async) === MATCHMAKING_STATE.PrivateLobbyJoined ||
      (matchMakingState$ | async) === MATCHMAKING_STATE.QueueJoined
    "
  >
    {{ "findPlayer" | translate }}
  </button>
  <button
    mat-raised-button
    class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
    (click)="createPrivateLobbyClicked()"
  >
    {{ "inviteFriend" | translate }}
  </button>
</div>

<app-queue-menu (leave)="leaveQueue()" [matchMakingState]="matchMakingState$"></app-queue-menu>

<app-private-lobby-menu
  [lobbyId]="(privateLobbyId$ | async) ?? ''"
  [matchMakingState]="(matchMakingState$ | async) ?? MATCHMAKING_STATE.None"
  (leave)="leavePrivateLobby()"
  (updatePrivateLobby)="updatePrivateLobby($event)"
></app-private-lobby-menu>

<div *ngIf="(matchMakingState$ | async) === MATCHMAKING_STATE.None" class="flex justify-center pt-8">
  <button
    mat-raised-button
    routerLink="../"
    class="bg-btn-primary dark:bg-btn-primary-d text-center text-white dark:text-slate-300"
  >
    <mat-icon>backspace</mat-icon>
    <span class="pl-2">{{ "allGames" | translate }}</span>
  </button>
</div>

<div *ngIf="(matchMakingState$ | async) === MATCHMAKING_STATE.None" class="pt-10 text-center text-sm">
  <app-download-links></app-download-links>
  <div>
    <a class="mr-2" href="mailto:contact@boardgames.io">contact&#64;boardgames.io</a>
    <a href="https://discord.gg/JFhujtnpHv" target="_blank">
      <button mat-button class="dark:text-slate-300">
        <img class="mr-2 inline" alt="Discord" src="assets/discord.svg" height="18" width="18" />Discord
      </button>
    </a>
  </div>
</div>
<div class="visible mt-4">
  <ng2-adsense adSlot="6790344836"></ng2-adsense>
</div>
