import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { emptyConnect4GameState, GameService } from "../../../../services/game.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GameViewContentService } from "../../../../services/game-view-content.service";
import { combineLatest, Subscription } from "rxjs";
import { Connect4GameState, GameType } from "@boardgames.io/messaging";
import { SoundService, SoundType } from "../../../../services/sound.service";

@Component({
  selector: "app-game-field",
  templateUrl: "./connect4-game-field.component.html",
})
export class Connect4GameFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  gameState = emptyConnect4GameState;

  subscriptions: Subscription[] = [];

  @ViewChild("gameField") gameField: ElementRef;

  constructor(
    public gameService: GameService,
    readonly router: Router,
    private activatedRoute: ActivatedRoute,
    readonly soundService: SoundService,
    private readonly gameViewContent: GameViewContentService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.gameService.gameState$.subscribe((gameState) => {
        if (gameState === undefined) {
          this.gameState = emptyConnect4GameState;
        } else {
          const newGameState = gameState as Connect4GameState;

          if (newGameState.turnCount > this.gameState.turnCount && newGameState.turnCount != 0) {
            this.soundService.playSound(SoundType.MakeMove);
          }
          this.gameState = newGameState;

          if (this.gameState.isFinished && this.gameState != emptyConnect4GameState) {
            this.router
              .navigate(["gameEnd"], {
                relativeTo: this.activatedRoute,
              })
              .catch();
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      combineLatest([this.gameViewContent.viewWidth, this.gameViewContent.viewHeight]).subscribe(([width, height]) => {
        const availableHeight = this.gameViewContent.maxGameFieldHeight(height) - 100;
        const availableWidth = width - 100;

        const maxWidthBasedOnHeight = availableHeight * (700 / 600);
        const maxHeightBasedOnWidth = availableWidth * (600 / 700);

        let finalWidth, finalHeight;

        if (maxWidthBasedOnHeight <= availableWidth) {
          finalWidth = maxWidthBasedOnHeight;
          finalHeight = availableHeight;
        } else {
          finalWidth = availableWidth;
          finalHeight = maxHeightBasedOnWidth;
        }

        if (finalWidth > 900) {
          finalWidth = 900;
          finalHeight = 900 * (600 / 700);
        }

        this.renderer.setStyle(this.gameField.nativeElement, "width", `${finalWidth}px`);
        this.renderer.setStyle(this.gameField.nativeElement, "height", `${finalHeight}px`);
      }),
    );
  }

  makeMove(column: number) {
    if (this.gameState.gameType != GameType.None && !this.gameState.isFinished) {
      this.gameService.makeTurn(column);
    }
  }

  stoneColor(stoneEntry?: string) {
    return {
      "stroke-stone-blue-border": stoneEntry === this.gameState?.players[0],
      "fill-stone-blue": stoneEntry === this.gameState?.players[0],
      "stroke-stone-red-border": stoneEntry === this.gameState?.players[1],
      "fill-stone-red": stoneEntry === this.gameState?.players[1],
      "stroke-white": stoneEntry !== this.gameState?.players[0] && stoneEntry !== this.gameState?.players[1],
      "dark:stroke-slate-800": stoneEntry !== this.gameState?.players[0] && stoneEntry !== this.gameState?.players[1],
      "fill-white": stoneEntry !== this.gameState?.players[0] && stoneEntry !== this.gameState?.players[1],
      "dark:fill-slate-800": stoneEntry !== this.gameState?.players[0] && stoneEntry !== this.gameState?.players[1],
    };
  }
}
