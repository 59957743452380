import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GameViewContentService {
  viewWidth = new BehaviorSubject<number>(1);
  viewHeight = new BehaviorSubject<number>(1);

  footerHeight = 32;
  headerHeight = 56;

  maxGameFieldHeight(currentHeight: number): number {
    return currentHeight - this.footerHeight - this.headerHeight;
  }
}
