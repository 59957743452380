<div class="flex flex-col items-center justify-center" *ngIf="!featureFlagService.isProd()">
  <div class="w-full rounded-lg bg-white p-8 shadow-md dark:bg-gray-800 md:w-1/3">
    <h2 class="mb-6 text-2xl font-semibold">Login</h2>
    <form>
      <div class="mb-4">
        <label for="username" class="mb-2 block text-sm">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          class="w-full rounded-lg border border-gray-300 p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:border-gray-500 dark:bg-gray-700 dark:focus:border-indigo-500 dark:focus:ring-indigo-200"
        />
      </div>
      <div class="mb-4">
        <label for="password" class="mb-2 block text-sm">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          class="w-full rounded-lg border border-gray-300 p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:border-gray-500 dark:bg-gray-700 dark:focus:border-indigo-500 dark:focus:ring-indigo-200"
        />
      </div>
      <div class="mb-4 flex items-center">
        <input type="checkbox" id="keepLoggedIn" name="keepLoggedIn" class="mr-2" />
        <label for="keepLoggedIn" class="text-sm">Keep me logged in</label>
      </div>
      <div class="mb-4">
        <a href="#" class="text-sm text-indigo-500 hover:underline dark:text-indigo-400"> Forgot password? </a>
      </div>
      <button
        type="submit"
        class="bg-btn-primary dark:bg-btn-primary-d w-full rounded-lg py-2 text-white dark:text-slate-300"
      >
        Login
      </button>
    </form>
  </div>
</div>
