import { GameType } from "./gameType";

export enum GameMessageType {
  MakeTurn = "game:maketurn",
  Update = "game:update",
  Reconnect = "game:reconnect",
  ReconnectError = "game:reconnectError",
  Leave = "game:leave",
}

export interface GameState {
  id: string;
  gameType: GameType;
  startingPlayer: string;
  currentPlayer: string;
  players: string[];
  isFinished: boolean;
  turnCount: number;
  winner?: string;
  opponentLeft: boolean;
  secondsPerMove: number;
  gameEndType: GameEndType;
  lastValidMoveDate?: Date;
  creationTime?: Date;
}

export enum GameEndType {
  None = "None",
  Regular = "Regular",
  Tie = "Tie",
  Leave = "Leave",
  Inactive = "Inactive",
}

export interface Connect4GameState extends GameState {
  board: string[][];
}

export interface TicTacToeGameState extends GameState {
  board: string[];
}

export interface GameUpdateMessage {
  message: {
    gameState: GameState;
    playerId: string;
  };
  signature?: string;
}
