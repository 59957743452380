import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FeatureFlagService } from "../../../services/feature-flag.service";
import { Observable } from "rxjs";
import { SettingsService } from "../../../services/settings.service";
import { GameService } from "../../../services/game.service";
import { PostGameLobbyService } from "../../../services/post-game-lobby.service";
import { MatchMakingService } from "../../../services/match-making.service";

@Component({
  selector: "boardgames-io-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  darkModeEnabled$: Observable<boolean>;
  soundEnabled$: Observable<boolean>;

  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private readonly settingsService: SettingsService,
    private readonly gameService: GameService,
    private readonly matchMakingService: MatchMakingService,
    private readonly postGameLobbyService: PostGameLobbyService,
  ) {}

  ngOnInit(): void {
    this.darkModeEnabled$ = this.settingsService.darkModeEnabled$;
    this.soundEnabled$ = this.settingsService.soundEnabled$;
  }

  async goBackToHome() {
    this.matchMakingService.leavePrivateLobby();
    this.gameService.leaveGame();
    this.postGameLobbyService.leavePostGameScreen();
    this.matchMakingService.leaveQueue();
  }

  toggleSound() {
    this.settingsService.toggleSound();
  }

  toggleDarkMode() {
    this.settingsService.toggleDarkMode();
  }

  isProd() {
    return this.featureFlagService.isProd();
  }
}
