import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslationService } from "../../../services/translation.service";

export interface Language {
  name: string;
  code: string;
}

@Component({
  selector: "language-switch",
  templateUrl: "./language-switch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitchComponent {
  languages: Language[] = [
    { name: "العربية", code: "ar" },
    { name: "Dansk", code: "da" },
    { name: "Deutsch", code: "de" },
    { name: "English", code: "en" },
    { name: "Español", code: "es" },
    { name: "Français", code: "fr" },
    { name: "Nederlands", code: "nl" },
    { name: "Svenska", code: "sv" },
  ];

  constructor(private translationService: TranslationService) {}

  useLanguage(language: string) {
    this.translationService.changeTranslation(language);
  }
}
