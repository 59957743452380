import { GameType } from "./gameType";
import { GameEndType } from "./gameMessages";

export enum PostGameLobbyClientMessageType {
  Rematch = "postGame:rematch",
  Leave = "postGame:leave",
  RematchResponse = "postGame:rematchResponse",
}

export enum PostGameLobbyServerMessageType {
  PostGameLobbyUpdate = "postGameLobby:update",
  PostGameLobbyNotFound = "postGameLobby:notFound",
}

export enum RematchPlayerResponseType {
  Accepted = "Accepted",
  Declined = "Declined",
}

export interface PostGameLobby {
  id: string;
  gameType: GameType;
  players: PostGameLobbyPlayer[];
  lastGameId: string;
  lastStartingPlayer: string;
  lastWinningPlayer: string | undefined;
  /*
   * True if the opponent left the game before it ended.
   */
  opponentLeft: boolean;
  gameEndType: GameEndType;
  secondsPerMove: number;
}

export interface PostGameLobbyPlayer {
  playerId: string;
  state: PostGameLobbyState;
}

export enum PostGameLobbyState {
  Joined = "JOINED",
  Left = "LEFT",
  ReadyForRematch = "READY_FOR_REMATCH",
  NoRematch = "NO_REMATCH",
}

export function getPostGameLobbyPlayerByPlayerId(
  postGameLobby: PostGameLobby,
  playerId: string,
): PostGameLobbyPlayer | undefined {
  return postGameLobby.players.find((player) => player.playerId === playerId);
}

export function getPostGameLobbyPlayerOpponentByPlayerId(
  postGameLobby: PostGameLobby,
  playerId: string,
): PostGameLobbyPlayer | undefined {
  return postGameLobby.players.find((player) => player.playerId !== playerId);
}
