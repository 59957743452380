<nav
  class="flex h-14 w-full items-center justify-between border-2 border-gray-100 bg-gray-50 p-3 dark:border-slate-900 dark:bg-slate-900"
>
  <a
    (click)="goBackToHome()"
    class="cursor-pointer text-xl font-medium text-gray-500 dark:text-slate-200"
    routerLink="/"
    >Boardgames.io</a
  >
  <div class="space-x-3">
    <language-switch></language-switch>
    <button (click)="toggleSound()" aria-label="Toggle Sound" class="mt-2">
      <mat-icon *ngIf="soundEnabled$ | async">volume_up</mat-icon>
      <mat-icon *ngIf="(soundEnabled$ | async) === false">volume_off</mat-icon>
    </button>
    <button (click)="toggleDarkMode()" aria-label="Toggle Dark Mode" class="mt-2">
      <mat-icon *ngIf="(darkModeEnabled$ | async) === false">wb_sunny</mat-icon>
      <mat-icon *ngIf="darkModeEnabled$ | async">mode_night</mat-icon>
    </button>

    <button *ngIf="!isProd()" aria-label="Login" class="mt-2" routerLink="de/login">
      <mat-icon>person</mat-icon>
    </button>
  </div>
</nav>
