import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrivateLobbySettingsMessage } from "@boardgames.io/messaging";
import { Store } from "@ngrx/store";
import {
  createPrivateLobby,
  exitedPrivateLobby,
  exitPrivateLobby,
  joinPrivateLobbyRequest,
  joinPrivateLobbyRequestFailed,
  joinQueueRequest,
  leaveFailedPrivateLobbyJoin,
  leaveQueue,
  privateLobbySettingsSendUpdate,
} from "../+state/game/game.actions";
import { FrontendGameState } from "../+state/game/game.reducer";
import { selectMatchmakingState, selectPrivateLobbyId } from "../+state/game/game.selectors";
import { SettingsState } from "../+state/settings/settings.reducer";
import { selectSecondsPerMove } from "../+state/settings/settings.selector";

export enum MatchmakingState {
  PrivateLobbyWaiting = "PrivateLobbyWaiting",
  QueueWaiting = "QueueWaiting",
  None = "None",
  QueueJoined = "QueueJoined",
  PrivateLobbyJoined = "PrivateLobbyJoined",
  PrivateLobbyJoinFailed = "PrivateLobbyJoinFailed",
}

@Injectable({
  providedIn: "root",
})
export class MatchMakingService {
  public readonly matchmakingState$: Observable<MatchmakingState>;
  public readonly privateLobbyId$: Observable<string | undefined>;
  public readonly secondsPerMove$: Observable<number>;

  constructor(readonly store: Store<{ game: FrontendGameState; settings: SettingsState }>) {
    this.matchmakingState$ = this.store.select(selectMatchmakingState);
    this.privateLobbyId$ = this.store.select(selectPrivateLobbyId);
    this.secondsPerMove$ = this.store.select(selectSecondsPerMove);
  }

  leaveQueue(): void {
    this.store.dispatch(leaveQueue());
  }

  joinQueue(): void {
    this.store.dispatch(joinQueueRequest());
  }

  updatePrivateLobbySettings(privateLobbySettings: PrivateLobbySettingsMessage) {
    this.store.dispatch(privateLobbySettingsSendUpdate({ privateLobbySettings }));
  }

  joinPrivateGame(lobbyId: string | undefined) {
    if (lobbyId) {
      this.store.dispatch(joinPrivateLobbyRequest({ lobbyId }));
    } else {
      this.store.dispatch(joinPrivateLobbyRequestFailed());
    }
  }

  leavePrivateLobby() {
    this.store.dispatch(exitPrivateLobby());
  }

  createPrivateLobby() {
    this.store.dispatch(createPrivateLobby());
  }

  leaveFailedPrivateLobbyJoin() {
    this.store.dispatch(leaveFailedPrivateLobbyJoin());
  }
}
