import { Component } from "@angular/core";
import { GameType } from "@boardgames.io/messaging";
import { Store } from "@ngrx/store";
import { changeGameType } from "../../../+state/game/game.actions";

@Component({
  selector: "boardgames.io-ticTacToe",
  templateUrl: "./ticTacToe.component.html",
})
export class TicTacToeComponent {
  GameType = GameType;

  constructor(private store: Store) {
    this.store.dispatch(changeGameType({ gameType: GameType.TicTacToe }));
  }
}
