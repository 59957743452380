<div
  class="flex w-full flex-col items-center space-y-4 p-4"
  *ngIf="
    matchMakingState === MatchmakingState.PrivateLobbyWaiting ||
    matchMakingState === MatchmakingState.PrivateLobbyJoined
  "
>
  <div *ngIf="matchMakingState !== MatchmakingState.PrivateLobbyJoined" class="flex flex-col items-center">
    <mat-spinner diameter="30" class="text-color-primary w-full"></mat-spinner>
    <p>{{ "waitingForJoin.message" | translate }}</p>
  </div>

  <div *ngIf="matchMakingState === MatchmakingState.PrivateLobbyJoined" class="flex flex-col items-center">
    <mat-icon class="text-green-600">done</mat-icon>
    <p>{{ "playerJoined.message" | translate }}</p>
  </div>

  <p class="space-y-0 p-0">{{ "shareLink.message" | translate }}</p>
  <mat-form-field class="flex w-full items-center align-bottom dark:bg-slate-800 dark:text-slate-300">
    <button mat-icon-button matPrefix class="text-blue-500">
      <mat-icon>link</mat-icon>
    </button>
    <input
      matInput
      [value]="invitationLink"
      (click)="copyLinkToClipboard(); showCopiedSnackbar()"
      readonly
      matTooltip="{{ 'copy' | translate }}"
      matTooltipPosition="above"
      class="flex-grow cursor-pointer dark:text-slate-300"
    />

    <button
      mat-icon-button
      class="text-blue-500"
      (click)="copyLinkToClipboard(); showCopiedSnackbar()"
      matSuffix
      matTooltip="{{ 'copy' | translate }}"
      matTooltipPosition="above"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
    <button (click)="shareLink()" class="text-blue-500" mat-icon-button matSuffix>
      <mat-icon matSuffix>ios_share</mat-icon>
    </button>
  </mat-form-field>
  <div class="align-items-center flex items-center dark:bg-slate-800 dark:text-slate-300">
    <mat-form-field class="ml-2 flex-grow items-center align-bottom dark:bg-slate-800 dark:text-slate-300">
      <mat-label class="dark:text-slate-300">{{ "privateGame.secondsPerMove.message" | translate }}</mat-label>
      <input
        matInput
        type="number"
        [ngModel]="timeoutPerMove"
        (ngModelChange)="onTimeoutPerMoveChange($event)"
        placeholder="{{ 'privateGame.secondsPerMove.message' | translate }}"
        min="10"
        max="3600"
        [defaultValue]="0"
        matTooltip="{{ 'timeoutTooltip' | translate }}"
        matTooltipPosition="above"
        class="number-input dark:text-slate-300"
        onkeyup="if(value<0) value=0;"
      />
    </mat-form-field>
  </div>

  <button
    mat-raised-button
    class="bg-btn-primary dark:bg-btn-primary-d text-white dark:text-slate-300"
    (click)="exitPrivateLobbyClicked()"
    type="button"
  >
    {{ "exitLobby.button" | translate }}
  </button>
  <qr-code *ngIf="qrCodeVisible$ | async" [value]="invitationLink" errorCorrectionLevel="M" size="250"></qr-code>
</div>
