import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "boardgames-io-footer",
  templateUrl: "./footer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
