import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { Socket } from "socket.io-client";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  ConnectionMessageType,
  GameMessageType,
  GameUpdateMessage,
  PostGameLobby,
  PostGameLobbyServerMessageType,
  PrivateLobbyMessage,
  QueueMessage,
  StatisticsMessageType,
} from "@boardgames.io/messaging";
import { FrontendGameState } from "../+state/game/game.reducer";
import { Store } from "@ngrx/store";
import {
  exitedPrivateLobby,
  gameStateUpdate,
  joinedPrivateLobby,
  postGameLobbyUpdateReceived,
  privateLobbyCreated,
  privateLobbyOpponentJoined,
  queueLeft,
  queuePlayerFound,
  queueWaiting,
  updateOnlinePlayersCount,
  updatePlayerId,
  wsConnectionUpdate,
} from "../+state/game/game.actions";
import { selectWebsocketConnected } from "../+state/game/game.selectors";

export const disconnectDelay = 3000;

@Injectable()
export class WebsocketService {
  public readonly connected$: Observable<boolean>;
  private readonly wsClient: Socket;

  constructor(private readonly store: Store<{ game: FrontendGameState }>) {
    this.connected$ = this.store.select(selectWebsocketConnected);
    this.wsClient = this.setupWebsocketClient();
    this.initHandlers();
  }

  private setupWebsocketClient(): Socket {
    const newWsClient = io.connect(this.getSocketIoUri(), {
      transports: ["websocket"],
      autoConnect: true,
    });
    newWsClient.on("connect", () => {
      this.store.dispatch(wsConnectionUpdate({ connected: true }));
    });
    newWsClient.on(ConnectionMessageType.Disconnect, () => {
      this.store.dispatch(wsConnectionUpdate({ connected: false }));
    });

    newWsClient.on(ConnectionMessageType.Reconnect, () => {
      this.store.dispatch(wsConnectionUpdate({ connected: true }));
    });
    return newWsClient;
  }

  emit(event: string, data: unknown) {
    this.wsClient.emit(event, data);
  }

  public initHandlers() {
    this.handlePlayerIdMessages();
    this.handleStatisticsMessages();
    this.handleGameMessages();
    this.handleMatchmakingMessages();
    this.handlePostGameLobbyMessages();
    this.reconnetionLogic();
  }

  private reconnetionLogic() {
    //websocketService.on(ConnectionMessageType.Reconnect, () => this.requestCurrentPlayerId());
    //websocketService.wsClient.on(ConnectionMessageType.Reconnect, () => this.requestOnlinePlayersCountUpdate());
    //websocketService.wsClient.io.on(ConnectionMessageType.Reconnect, () => {
    //  this.gameState$.pipe(first()).subscribe((gameUpdateMessage) => {
    //    if (
    //      gameUpdateMessage &&
    //      gameUpdateMessage.message.gameState.isFinished === false &&
    //      gameUpdateMessage.signature &&
    //      ReconnectMessageValidator.isCreationTimeLessThanAnHourOld(gameUpdateMessage?.message?.gameState)
    //    ) {
    //      this.wsClient.emit(GameMessageType.Reconnect, gameUpdateMessage);
    //    }
    //  });
    //});
    //this.wsClient.io.on(ConnectionMessageType.Reconnect, () => {
    //  if (
    //    this.matchmakingStateForReconnect === MATCHMAKING_STATE.QUEUE_WAITING ||
    //    this.matchmakingStateForReconnect === MATCHMAKING_STATE.QUEUE_JOINED
    //  ) {
    //    this.joinQueue();
    //  }
    //});
    //this.wsClient.on(ConnectionMessageType.Disconnect, () => {
    //  this.matchmakingStateForReconnect = this.matchmakingState.getValue();
    //  setTimeout(() => this.matchmakingState.next(MATCHMAKING_STATE.NONE), disconnectDelay);
    //});
    //
  }

  private handlePlayerIdMessages() {
    this.wsClient.on(ConnectionMessageType.PlayerId, (playerId: string) => {
      this.store.dispatch(updatePlayerId({ playerId }));
    });
  }

  private handleStatisticsMessages() {
    this.wsClient.on(StatisticsMessageType.OnlinePlayersCount, (onlinePlayersCount: number) =>
      this.store.dispatch(updateOnlinePlayersCount({ onlinePlayersCount })),
    );
  }

  private handleMatchmakingMessages() {
    this.handlePublicQueueEvents();
    this.handlePrivateLobbyEvents();
  }

  private handlePublicQueueEvents() {
    this.wsClient.on(QueueMessage.Join, () => {
      this.store.dispatch(queueWaiting());
    });
    this.wsClient.on(QueueMessage.Leave, () => this.store.dispatch(queueLeft()));
    this.wsClient.on(QueueMessage.PlayerFound, () => {
      this.store.dispatch(queuePlayerFound());
    });
  }

  private handlePrivateLobbyEvents() {
    this.wsClient.on(PrivateLobbyMessage.Created, (lobbyId: string) => {
      this.store.dispatch(privateLobbyCreated({ lobbyId }));
    });

    this.wsClient.on(PrivateLobbyMessage.Join, (response: { success: boolean }) => {
      this.store.dispatch(joinedPrivateLobby(response));
    });

    this.wsClient.on(PrivateLobbyMessage.Leave, () => {
      this.store.dispatch(exitedPrivateLobby());
    });

    this.wsClient.on(PrivateLobbyMessage.OpponentJoined, () => {
      this.store.dispatch(privateLobbyOpponentJoined());
    });
    //this.wsClient.on(
    //  PrivateLobbyMessage.UpdateSettings,
    //  (privateLobbySettingsMessage: PrivateLobbySettingsMessage) => {
    //    //this.settingsService.setTimeoutPerMove(privateLobbySettingsMessage.secondsPerMove);
    //    this.privateLobbyId = privateLobbySettingsMessage.lobbyId;
    //  },
    //);
  }

  private handleGameMessages() {
    this.wsClient.on(GameMessageType.Update, (gameUpdateMessage: GameUpdateMessage) => {
      this.store.dispatch(gameStateUpdate({ gameState: gameUpdateMessage.message.gameState }));
    });
  }

  private handlePostGameLobbyMessages() {
    this.wsClient.on(PostGameLobbyServerMessageType.PostGameLobbyUpdate, (postGameLobby: PostGameLobby) => {
      this.store.dispatch(postGameLobbyUpdateReceived({ postGameLobby }));
    });
  }

  private getSocketIoUri(): string {
    if (environment.production) {
      return `https://api.${window.location.hostname}`;
    } else {
      return environment.socket_io_uri;
    }
  }
}
