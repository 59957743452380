import { Injectable } from "@angular/core";
import { SettingsState } from "../+state/settings/settings.reducer";
import { Store } from "@ngrx/store";
import { selectSoundEnabled } from "../+state/settings/settings.selector";
import { Observable } from "rxjs";

export enum SoundType {
  MakeMove,
  MatchStarting,
}

export interface SoundInfo {
  filename: string;
  volume?: number;
}

@Injectable({
  providedIn: "root",
})
export class SoundService {
  soundFiles = new Map<SoundType, SoundInfo>([
    [SoundType.MatchStarting, { filename: "matchmaking_start.wav", volume: 0.4 }],
    [SoundType.MakeMove, { filename: "makemove.wav" }],
  ]);

  private preloadedSounds = new Map<SoundType, HTMLAudioElement>();

  private readonly soundEnabled$: Observable<boolean>;
  private soundEnabled: boolean;

  constructor(private readonly store: Store<{ settings: SettingsState }>) {
    this.soundEnabled$ = this.store.select(selectSoundEnabled);

    this.soundEnabled$.subscribe((soundEnabled) => {
      this.soundEnabled = soundEnabled;
    });

    this.preloadSounds();
  }

  playSound(soundType: SoundType) {
    const sound = this.preloadedSounds.get(soundType);
    if (this.soundEnabled && sound) {
      sound.volume = sound.volume ?? 1; // Set volume or default to 1
      sound.play().catch((e) => console.error("Error playing sound:", e));
    }
  }

  private preloadSounds() {
    this.soundFiles.forEach((soundInfo, soundType) => {
      const audio = new Audio(`../assets/sounds/${soundInfo.filename}`);
      audio.load(); // Preload sound
      this.preloadedSounds.set(soundType, audio);
    });
  }
}
