<div class="flex flex-grow flex-col items-center justify-center w-full h-full stroke-5">
  <div #gameField class="relative">
    <svg class="stroke-board dark:stroke-board-slate absolute -z-10" viewBox="6 16 204 204">
      <!-- Horizontal Lines -->
      <path d="M6,84L210,84" style="stroke-dasharray: 204; stroke-dashoffset: 0"></path>
      <path d="M6,152L210,152" style="stroke-dasharray: 204; stroke-dashoffset: 0"></path>

      <!-- Vertical Lines -->
      <path d="M74,16L74,220" style="stroke-dasharray: 204; stroke-dashoffset: 0"></path>
      <path d="M142,16L142,220" style="stroke-dasharray: 204; stroke-dashoffset: 0"></path>
    </svg>

    <div class="grid grid-cols-3 w-full gap-2.5">
      <div (click)="makeMove(fieldId)" *ngFor="let fieldId of [0, 1, 2, 3, 4, 5, 6, 7, 8]" class="w-full">
        <div class="flex justify-center items-center w-full">
          <svg
            *ngIf="displayX(gameState.board[fieldId])"
            [ngClass]="{ 'animate-xo': displayX(gameState.board[fieldId]) }"
            aria-label="X"
            class="stroke-stone-blue dark:stroke-stone-blue-slate w-full"
            role="img"
            viewBox="0 0 128 128"
          >
            <path d="M16,16L112,112" style="stroke-dasharray: 135.764; stroke-dashoffset: 0"></path>
            <path d="M112,16L16,112" style="stroke-dasharray: 135.764; stroke-dashoffset: 0"></path>
          </svg>

          <svg
            *ngIf="displayO(gameState.board[fieldId])"
            [ngClass]="{ 'animate-xo': displayO(gameState.board[fieldId]) }"
            aria-label="X"
            class="stroke-stone-red dark:stroke-stone-red-slate w-full"
            role="img"
            viewBox="0 0 128 128"
          >
            <path
              d="M64,16A48,48 0 1,0 64,112A48,48 0 1,0 64,16"
              fill="none"
              style="stroke-dasharray: 301.635; stroke-dashoffset: 0"
            ></path>
          </svg>

          <svg
            *ngIf="displayEmpty(gameState.board[fieldId])"
            aria-label="X"
            class="w-full"
            role="img"
            viewBox="0 0 128 128"
          ></svg>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5 flex w-full items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <div class="stroke-10 h-10 w-10">
        <svg
          *ngIf="displayX(gameState.currentPlayer)"
          [ngClass]="{ 'animate-xo': displayX(gameState.currentPlayer) }"
          aria-label="X"
          class="stroke-stone-blue dark:stroke-stone-blue-slate w-full"
          role="img"
          viewBox="0 0 128 128"
        >
          <path d="M16,16L112,112" style="stroke-dasharray: 135.764; stroke-dashoffset: 0"></path>
          <path d="M112,16L16,112" style="stroke-dasharray: 135.764; stroke-dashoffset: 0"></path>
        </svg>

        <svg
          *ngIf="displayO(gameState.currentPlayer)"
          [ngClass]="{ 'animate-xo': displayO(gameState.currentPlayer) }"
          aria-label="X"
          class="stroke-stone-red dark:stroke-stone-red-slate w-full"
          role="img"
          viewBox="0 0 128 128"
        >
          <path
            d="M64,16A48,48 0 1,0 64,112A48,48 0 1,0 64,16"
            fill="none"
            style="stroke-dasharray: 301.635; stroke-dashoffset: 0"
          ></path>
        </svg>

        <svg
          *ngIf="displayEmpty(gameState.currentPlayer)"
          aria-label="X"
          class="w-full"
          role="img"
          viewBox="0 0 128 128"
        ></svg>
      </div>
      <p *ngIf="(gameService.isMyTurn$ | async) && gameState.currentPlayer !== ''">
        {{ "yourTurn.message" | translate }}
      </p>
      <p *ngIf="(gameService.isMyTurn$ | async) === false && gameState.currentPlayer !== ''">
        {{ "opponentsTurn.message" | translate }}
      </p>
    </div>
  </div>
</div>
