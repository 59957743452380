import { Component, OnInit } from "@angular/core";
import { WebsocketService } from "../../services/websocket.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "disconnected",
  templateUrl: "./disconnected.component.html",
  styleUrls: ["./disconnected.component.scss"],
})
export class DisconnectedComponent implements OnInit {
  hidden$: Observable<boolean>;

  constructor(private readonly websocketService: WebsocketService) {}

  ngOnInit(): void {
    this.hidden$ = this.websocketService.connected$.pipe(tap((connected) => console.log("connected", connected)));
  }
}
