import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainMenuComponent } from "./components/menu/main-menu/main-menu.component";
import { JoinGameComponent } from "./components/menu/private-lobby-join/join-game.component";
import { PostGameLobbyMenuComponent } from "./components/menu/post-game-lobby/post-game-lobby-menu.component";
import { Connect4Component } from "./components/games/connect4/connect4.component";
import { HomeComponent } from "./components/home/home.component";
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from "@gilsdav/ngx-translate-router";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { TicTacToeComponent } from "./components/games/ticTacToe/ticTacToe.component";
import { LoginComponent } from "./components/login/login.component";

const children = [
  { path: "", component: MainMenuComponent },
  { path: "gameEnd", component: PostGameLobbyMenuComponent },
  { path: "join", component: JoinGameComponent },
];

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  { path: "connect4", component: Connect4Component, children },
  { path: "tictactoe", component: TicTacToeComponent, children },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: TranslateService, location: Location, settings: LocalizeRouterSettings) =>
          new ManualParserLoader(translate, location, settings, ["en", "da", "de", "fr", "es", "ar", "nl", "sv"]),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
