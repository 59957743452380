<div class="flex flex-grow flex-col items-center justify-center w-full h-full stroke-5">
  <div #gameField class="relative mt-2">
    <div class="p-2 grid absolute grid-cols-7 w-full h-full gap-1.5 z-10">
      <div (click)="makeMove(columnId)" *ngFor="let columnId of [0, 1, 2, 3, 4, 5, 6]" class="w-full h-full"></div>
    </div>

    <div class="p-2 grid absolute grid-cols-7 w-full h-full -z-10">
      <div *ngFor="let columnId of [0, 1, 2, 3, 4, 5, 6]" class="flex flex-wrap flex-col-reverse">
        <div *ngFor="let rowId of [5, 4, 3, 2, 1, 0]">
          <svg [ngClass]="stoneColor(gameState.board[rowId][columnId])" class="w-full" role="img" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="42" />
          </svg>
        </div>
      </div>
    </div>
    <svg
      class="stroke-board fill-board dark:fill-board-slate absolute -z-20 w-full h-full"
      role="img"
      viewBox="0 0 700 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30,0 h640 a30,30 0 0 1 30,30 v540 a30,30 0 0 1 -30,30 h-640 a30,30 0 0 1 -30,-30 v-540 a30,30 0 0 1 30,-30 z"
        stroke-width="0"
      />
    </svg>
  </div>
  <div class="flex min-h-[100px] w-full items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <svg
        [ngClass]="stoneColor(gameState.currentPlayer)"
        aria-label="X"
        class="w-8 h-8 lg:w-16 lg:h-16"
        role="img"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="40" />
      </svg>
      <p *ngIf="(gameService.isMyTurn$ | async) && gameState.currentPlayer !== ''">
        {{ "yourTurn.message" | translate }}
      </p>
      <p *ngIf="(gameService.isMyTurn$ | async) === false && gameState.currentPlayer !== ''">
        {{ "opponentsTurn.message" | translate }}
      </p>
    </div>
  </div>
</div>
