import { Component } from "@angular/core";
import { GameType } from "@boardgames.io/messaging";
import { Store } from "@ngrx/store";
import { changeGameType } from "../../../+state/game/game.actions";

@Component({
  selector: "connect4",
  templateUrl: "./connect4.component.html",
})
export class Connect4Component {
  GameType = GameType;

  constructor(private store: Store) {
    this.store.dispatch(changeGameType({ gameType: GameType.Connect4 }));
  }
}
