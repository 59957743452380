import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  PostGameLobbyFrontendState,
  PostGameLobbyService,
  WinnerState,
} from "../../../services/post-game-lobby.service";
import { Subscription } from "rxjs";
import { MenuService } from "../../../services/menu.service";
import { GameEndType, PostGameLobby } from "@boardgames.io/messaging";

@Component({
  selector: "app-post-game-lobby",
  templateUrl: "./post-game-lobby-menu.component.html",
  styleUrls: ["./post-game-lobby-menu.component.scss"],
})
export class PostGameLobbyMenuComponent implements OnInit, OnDestroy {
  WinnerState = WinnerState;
  PostGameLobbyState = PostGameLobbyFrontendState;
  GameEndType = GameEndType;

  subscriptions: Subscription[] = [];

  winnerState: WinnerState = WinnerState.Win;
  rematchRequestState = PostGameLobbyFrontendState.OpponentLeft;
  postGameLobby: PostGameLobby | undefined;

  constructor(
    public postGameLobbyService: PostGameLobbyService,
    public menuService: MenuService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.postGameLobbyService.postGameLobbyState$.subscribe(
        (rematchRequestState) => (this.rematchRequestState = rematchRequestState),
      ),
    );
    this.subscriptions.push(this.postGameLobbyService.winnerState$.subscribe((state) => (this.winnerState = state)));

    this.subscriptions.push(
      this.postGameLobbyService.postGameLobby$.subscribe((lobby) => (this.postGameLobby = lobby)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.postGameLobbyService.leavePostGameScreen();
  }

  requestRematch() {
    this.postGameLobbyService.requestRematch();
  }

  acceptRematch() {
    this.postGameLobbyService.acceptRematch();
  }

  declineRematch() {
    this.postGameLobbyService.declineRematch();
  }

  switchMenuMaximizationStates() {
    this.menuService.switchMenuStates();
  }
}
