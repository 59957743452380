<a href="https://play.google.com/store/apps/details?id=io.boardgames.android" target="_blank">
  <img
    class="m-0 inline p-0 hover:cursor-pointer"
    alt="Ios Badge"
    src="assets/app_badges/android/google-play-badge-en.png"
    width="150"
    height="58"
  />
</a>
<div *ngIf="false">
  <a href="https://apps.apple.com/de/app/telegram-messenger/toBeReplaced" target="_blank">
    <button>
      <img class="m-0 inline pr-2" alt="Ios Badge" src="assets/app_badges/ios/app-store-badge-en.svg" />
    </button>
  </a>
</div>
