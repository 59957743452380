import { createSelector } from "@ngrx/store";
import { SettingsState } from "./settings.reducer";

export const selectSettings = (state: { settings: SettingsState }) => state.settings;

export const selectSoundEnabled = createSelector(selectSettings, (settings: SettingsState) => settings.isSoundEnabled);

export const selectDarkModeEnabled = createSelector(
  selectSettings,
  (settings: SettingsState) => settings.isDarkModeEnabled,
);

export const selectSecondsPerMove = createSelector(
  selectSettings,
  (settings: SettingsState) => settings.secondsPerMove,
);
