import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap, withLatestFrom } from "rxjs/operators";
import {
  changeGameType,
  createPrivateLobby,
  exitPrivateLobby,
  joinPrivateLobbyRequest,
  joinQueueRequest,
  leaveGame,
  leaveQueue,
  makeGameMove,
  postGameLobbyAcceptRematch,
  postGameLobbyDeclineRematch,
  postGameLobbyLeave,
  postGameLobbyRequestRematch,
  postGameLobbyUpdateReceived,
  privateLobbySettingsSendUpdate,
  queuePlayerFound,
} from "./game.actions";
import { WebsocketService } from "../../services/websocket.service";
import {
  CreatePrivateLobbyMessage,
  GameMessageType,
  PostGameLobbyClientMessageType,
  PrivateLobbyMessage,
  QueueMessage,
  RematchPlayerResponseType,
  StatisticsMessageType,
} from "@boardgames.io/messaging";
import { Store } from "@ngrx/store";
import { FrontendGameState } from "./game.reducer";
import { selectGameType } from "./game.selectors";
import { ngrxEffectsInit } from "../common/common.actions";
import { selectSecondsPerMove } from "../settings/settings.selector";
import { SettingsState } from "../settings/settings.reducer";
import { SoundService, SoundType } from "../../services/sound.service";

@Injectable()
export class GameEffects {
  joinQueue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(joinQueueRequest),
        withLatestFrom(this.store.select(selectGameType)),
        tap(([, gameType]) => {
          this.websocketService.emit(QueueMessage.Join, gameType);
        }),
      ),
    { dispatch: false },
  );

  retrieveOnlinePlayersCount = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeGameType, ngrxEffectsInit),
        withLatestFrom(this.store.select(selectGameType)),
        tap(([, gameType]) => {
          this.websocketService.emit(StatisticsMessageType.OnlinePlayersCount, gameType);
        }),
      ),
    { dispatch: false },
  );

  queuePlayerFound = createEffect(
    () =>
      this.actions$.pipe(
        ofType(queuePlayerFound),
        tap(() => {
          this.soundService.playSound(SoundType.MatchStarting);
        }),
      ),
    { dispatch: false },
  );

  leavePublicQueue = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveQueue),
        withLatestFrom(this.store.select(selectGameType)),
        tap(([, gameType]) => {
          this.websocketService.emit(QueueMessage.Leave, gameType);
        }),
      ),
    { dispatch: false },
  );

  createPrivateLobby = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createPrivateLobby),
        withLatestFrom(this.store.select(selectGameType), this.store.select(selectSecondsPerMove)),
        tap(([, gameType, secondsPerMove]) => {
          const createPrivateLobbyMessage: CreatePrivateLobbyMessage = {
            gameType,
            secondsPerMove,
          };
          this.websocketService.emit(PrivateLobbyMessage.CreateV2, createPrivateLobbyMessage);
        }),
      ),
    { dispatch: false },
  );

  exitPrivateLobby = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exitPrivateLobby),
        tap(() => {
          this.websocketService.emit(PrivateLobbyMessage.Leave, {});
        }),
      ),
    { dispatch: false },
  );

  makeGameMove = createEffect(
    () =>
      this.actions$.pipe(
        ofType(makeGameMove),
        tap((action) => {
          this.websocketService.emit(GameMessageType.MakeTurn, action.columnId);
        }),
      ),
    { dispatch: false },
  );

  postGameLobbyRequestRematch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postGameLobbyRequestRematch),
        tap(() => {
          this.websocketService.emit(PostGameLobbyClientMessageType.Rematch, {});
        }),
      ),
    { dispatch: false },
  );

  postGameLobbyAcceptRematch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postGameLobbyAcceptRematch),
        tap(() => {
          this.websocketService.emit(
            PostGameLobbyClientMessageType.RematchResponse,
            RematchPlayerResponseType.Accepted,
          );
        }),
      ),
    { dispatch: false },
  );

  postGameLobbyDeclineRematch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postGameLobbyDeclineRematch),
        tap(() => {
          this.websocketService.emit(
            PostGameLobbyClientMessageType.RematchResponse,
            RematchPlayerResponseType.Declined,
          );
        }),
      ),
    { dispatch: false },
  );

  postGameLobbyLeave = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postGameLobbyLeave),
        withLatestFrom(this.store.select(selectGameType)),
        tap(() => {
          this.websocketService.emit(PostGameLobbyClientMessageType.Leave, {});
        }),
        map(([, gameType]) => changeGameType({ gameType })),
      ),
    { dispatch: true },
  );

  leaveGame = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveGame),
        tap(() => {
          this.websocketService.emit(GameMessageType.Leave, {});
        }),
      ),
    { dispatch: false },
  );

  joinPrivateLobbyRequest = createEffect(
    () =>
      this.actions$.pipe(
        ofType(joinPrivateLobbyRequest),
        tap((action) => {
          this.websocketService.emit(PrivateLobbyMessage.Join, action.lobbyId);
        }),
      ),
    { dispatch: false },
  );

  sendPrivateLobbySettingsUpdate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(privateLobbySettingsSendUpdate),
        tap((action) => {
          this.websocketService.emit(PrivateLobbyMessage.UpdateSettings, action.privateLobbySettings);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<{ game: FrontendGameState; settings: SettingsState }>,
    private readonly websocketService: WebsocketService,
    private readonly soundService: SoundService,
  ) {}
}
